<template>
  <div>
    <div>
      <el-form class="demo-form-inline">
        <el-form-item label="分类:">
          <el-select placeholder="分类" v-model="formInline.classification">
            <el-option label="全部" value="all"></el-option>
            <el-option label="行业资讯" value="hyzx"></el-option>
            <el-option label="政策法规" value="zcfg"></el-option>
            <el-option label="专家解读" value="zjjd"></el-option>
            <el-option label="信用文化" value="xywh"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题名称:">
          <el-input
            placeholder="标题名称"
            v-model="formInline.title"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData(1)">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmit">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <el-table
        :data="data.data"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-click="detail"
      >
        <el-table-column
          prop="title"
          label="标题名称"
          style="cursor: pointer"
        ></el-table-column>
        <el-table-column align="center" label="分类" style="cursor: pointer">
          <template #default="scope">
            <span v-if="scope.row.classification == 'hyzx'">行业资讯</span>
            <span v-if="scope.row.classification == 'zcfg'">政策法规</span>
            <span v-if="scope.row.classification == 'zjjd'">专家解读</span>
            <span v-if="scope.row.classification == 'xywh'">信用文化</span>
          </template></el-table-column
        >
        <el-table-column
          label="展示状态"
          align="center"
          style="cursor: pointer"
        >
          <template #default="">
            <span>公开</span>
            <!-- <span v-if="scope.row.status == 1">公开</span>
            <span v-if="scope.row.status == 0">下架</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="发布时间"
          align="center"
          style="cursor: pointer"
        >
          <template #default="scope"
            >{{ $utils.formatDate(scope.row.createDate, "yyyy-MM-dd hh:mm") }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="操作"
          width="100"
          style="cursor: pointer"
        >
          <template #default="scope">
            <el-button
              @click.stop="handleEdit(scope.row)"
              type="text"
              size="small"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click.stop="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :page-size="10"
          :total="data.totalCount"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
    <div>
      <el-dialog
        v-model="dialogVisible"
        width="400px"
      >
        <h3 style="text-align: center">确定将该文章删除，删除后不能恢复</h3>
        <div class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="hadeDelete">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      dialogVisible: false,
      itemDel: [],
      formInline: {
        title: "",
        classification: "all",
      },
    };
  },
  methods: {
    async getData(page) {
      const params = {
        pageSize: 10,
        currentPage: page,
        title:this.formInline.title
      };
      const res = await this.$api.newsManagement.getNewList(this.formInline.classification,params);
      if (res.code === 0) {
        this.data = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleEdit(data) {
      this.$router.push({
        path: `/information/editNews/${data.infoId}`,
      });
    },
    handleDel(data) {
      this.dialogVisible = true;
      this.itemDel = data;
    },
  
    detail(val) {
      this.$router.push({
        path: `/information/previewNews`,
      });
      localStorage.setItem("title", val.title);
      localStorage.setItem("content", val.content);
    },
    // 新增一条新闻
    addSubmit() {
      this.$router.push("/information/addnews");
    },
    async hadeDelete() {
      const res = await this.$api.newsManagement.deleteNew(this.itemDel.infoId);
      if (res.data.code == 0) {
        this.getData(this.data.currentPage);
        this.$message.success('删除成功');
        this.dialogVisible = false;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 分页
    async changePage(val) {
      
      this.getData(val)
    },
  },
  mounted() {
    this.getData(1);
  },
  filters: {},
};
</script>

<style lang="scss" scoped>
.upload-file {
  height: 40px;
  line-height: 40px;
}
.file-img {
  .el-image {
    width: 200px;
    max-height: 200px;
    vertical-align: top;
    &:first-child {
      margin-right: 10px;
    }
  }
}
.container {
  cursor: pointer;
}
.dialog-footer {
  margin-top: 20px;
  text-align: center;
}
.demo-form-inline {
  display: flex;
  cursor: pointer;
  .el-form-item {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>
